<template>
  <div class="page-works">
    <list-page
      ref="listPageRef"
      inline
      label-width="80px"
      :model="model"
      :fields="fields"
      @submit="submit"
      :columns="columns"
      :fetch="fetch"
      :initLoad="initLoad"
      :headerCellStyle="{ background: '#f9fafc' }"
      :submitter="{
        props: {
          style: {
            display: 'flex',
            justifyContent: 'flex-end'
          }
        }
      }"
    >
      <template #addon>
        <div style="margin-bottom: 16px">
          <el-upload
            :headers="headers"
            :action="action"
            :show-file-list="false"
            :on-success="uploadSuccess"
            :on-progress="uploadProcess"
            :on-error="uploadError"
            style="display: inline-block; margin-left: 10px"
          >
            <el-button :loading="uploadLoading">批量导入作品</el-button>
          </el-upload>
          <el-button
            style="margin-left: 10px"
            @click="downloadWorks"
            :loading="downloadLoading"
          >
            导出作品列表
          </el-button>
          <el-button
            style="margin-left: 10px"
            @click="clearExcelCache"
            :loading="clearExcelCacheLoading"
          >
            清除excel导入缓存
          </el-button>
        </div>
      </template>
    </list-page>
  </div>
</template>

<script>
import localStore from 'store'
import { baseUrl } from '@/config/env'
import * as ConstantRouter from '@/constant/router'
import { exportFile } from '@/utils/tools'

export default {
  data() {
    return {
      action: baseUrl + '/api/jsImport/uploadExcel',
      uploadLoading: false,
      downloadLoading: false,
      clearExcelCacheLoading: false,
      restaurants: [],
      initLoad: true,
      model: {
        name: ''
      },
      fields: [
        {
          type: 'input',
          formItemProps: {
            label: '作品名称',
            prop: 'name'
          }
        }
      ],
      columns: [
        {
          label: '作品名称',
          prop: 'name',
          render(_, text) {
            return text || '-'
          }
        },
        {
          label: '作品简介',
          prop: 'desc',
          render(_, text) {
            return text || '-'
          }
        },
        {
          label: '作品版面',
          prop: 'faceImg',
          render(_, val) {
            if (!val) return '-'
            return (
              <el-image
                style="width: 100px; height: 100px"
                src={val}
                preview-src-list={[val]}
              ></el-image>
            )
          }
        },
        {
          label: '队长信息',
          prop: 'leader',
          render(_, val) {
            if (!val) return '-'
            return val.name + ' | ' + val.phoneNum + ' | ' + val.unit
          }
        },
        {
          label: '指导教师信息',
          prop: 'teacher',
          render(_, val) {
            if (!val) return '-'
            if (!val.length) return '-'
            return (
              <ul>
                {val.map((item, index) => {
                  return (
                    <li key={item.id}>
                      {index +
                        1 +
                        '. ' +
                        item.name +
                        ' | ' +
                        item.phoneNum +
                        ' | ' +
                        item.unit}
                    </li>
                  )
                })}
              </ul>
            )
          }
        },
        {
          label: '操作',
          width: '160px',
          render: (h, record) => {
            return (
              <div>
                <div>
                  <el-button type="text" onClick={() => this.toDetail(record)}>
                    详情
                  </el-button>
                </div>
              </div>
            )
          }
        }
      ]
    }
  },
  computed: {
    headers() {
      return {
        token: localStore.get('dashboard-token')
      }
    }
  },
  created() {
    const currentPage = localStore.get('dashboard/works')
    if (currentPage) {
      this.initLoad = false
    }
  },
  mounted() {
    // console.log(this.$refs.listPageRef, 1)
    const currentPage = localStore.get('dashboard/works')
    if (currentPage) {
      this.$refs.listPageRef.fetchData({ pageSize: 10, currentPage })
    }
  },
  methods: {
    click() {
      console.log(this.model, this.formRef)
    },
    submit() {
      console.log(this.model)
    },
    fetch({ currentPage, pageSize, ...req }) {
      localStore.set('dashboard/works', currentPage)
      return this.$serve.jsOpus
        .getPageListAdmin({
          data: {
            pageIndex: currentPage,
            pageSize: pageSize,
            ...req
          }
        })
        .then(([, { records, ...res }]) => {
          return {
            ...res,
            list: records
          }
        })
    },
    toDetail(record) {
      this.$router.push({
        name: ConstantRouter.DASHBOARD_WORKS_DETAIL,
        params: {
          id: record.id
        }
      })
    },
    uploadProcess() {
      this.uploadLoading = true
    },
    uploadError() {
      this.uploadLoading = false
      this.$message.success('导入失败')
    },
    uploadSuccess() {
      this.uploadLoading = false
      this.$message.success('导入成功')
      this.$refs.listPageRef.refresh()
    },
    async downloadWorks() {
      this.downloadLoading = true
      const [err, res] = await this.$serve.jsOpus.exportOpusList({
        data: {
          jsId: 10
        }
      })
      this.downloadLoading = false
      if (err === this.$serve.FAIL) return
      exportFile(res.file, res.name)
    },
    async clearExcelCache() {
      this.clearExcelCacheLoading = true
      const [err] = await this.$serve.jsImport.clearExcelCache()
      this.clearExcelCacheLoading = false
      if (err === this.$serve.FAIL) return
    }
  }
}
</script>
